<template>
    <template v-if="moduleLoaded">
        <div :id="uid" ref="froalaEditorRef" class="h-100 o365-froala"></div>
    </template>
</template>

<script setup lang="ts">
import { importUtils, logger } from 'o365-utils';
import { ref, nextTick, onBeforeUnmount, Ref } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';

const props = defineProps<{
    markup: string;
}>();

const uid: string = `froala-editor-${crypto.randomUUID()}`
const cleanupTokens: (() => void)[] = [];
const moduleLoaded: Ref<boolean> = ref(false);
const modalImages = ref();
const editorObj = ref();

let froalaEditor = null;
const froalaEditorRef = ref<HTMLElement>();

const dsImagesArchive = getOrCreateDataObject({
    "id": "dsImagesArchive",
    "viewName": "sviw_Image_Images2",
    "distinctRows": false,
    "fields": [
        {
            "name": "ID",            
            "type": null
        },
        {
            "name": "FileName",            
            "type": null
        },
        {
            "name": "Description",            
            "type": null
        },
        {
            "name": "PrimKey",            
            "type": null
        },
        {
            "name": "Created",
            "sortOrder": 1,
            "sortDirection": "desc",
            "type": null
        }
    ],    
    "maxRecords": 30,
    "dynamicLoading": false,
});

// init FroalaEditor
function initEditor() {
    froalaEditor = new FroalaEditor(froalaEditorRef.value, {
        key: '1CC3kD9B5E4F6G4F3bHIMFF1EWBXIJb1BZLZFh1i1MXQLjE4C3F3I3B4D6C6E3C3F2==',
        placeholderText: "",
        charCounterCount: false,
        height: '100%',
        attribution: false,
        useClasses: true,
        pastePlain: true,
        theme: "dark",
        toolbarInline: false,
    }, (a, b, c) => {
        // disable editing, toolbar and set markup from parent component
        froalaEditor.edit.off();
        froalaEditor.toolbar.hide();
        froalaEditor.html.set(props.markup);
    });

    froalaInstances.set(uid, {
        froalaEditor,
        getProps: () => props,
        getImagesDataObject: () => dsImagesArchive,
        getModalImages: () => modalImages.value
    });

    return froalaEditor;
}

// load editor
loadFroala().then(() => {
    moduleLoaded.value = true;
    return nextTick();
}).then(() => {
    editorObj.value = initEditor();    
}).catch(ex => {
    logger.error(ex);
});

onBeforeUnmount(() => {
    cleanupTokens.forEach(ct => ct);
    froalaInstances.delete(uid);
});

</script>

<script lang="ts">
// setup and imports
let FROALA_INIT_PROMISE: Promise<void> | null = null;
const froalaInstances = new Map<string, any>();
async function loadFroala() {
    if (FROALA_INIT_PROMISE == null) {
        let resolve = () => { };
        let reject = (pEx: Error) => { };
        FROALA_INIT_PROMISE = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        importUtils.loadScript('froala-editor/js/froala_editor.pkgd.min.js').then(() => {
            return importUtils.loadScript('froala-editor/js/plugins/word_paste.min.js');
        }).then(() => {
            return importUtils.loadStyle('froala-editor/css/froala_editor.pkgd.min.css');
        }).then(() => {

            resolve();
        }).catch((ex) => {
            reject(ex);
        });

        return FROALA_INIT_PROMISE;
    } else {
        return FROALA_INIT_PROMISE;
    }
}
</script>

<style>
.o365-froala .fr-wrapper h1 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
.o365-froala .fr-wrapper h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: .5rem;
    margin-bottom: 0px;
}
</style>